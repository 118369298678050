import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/ContactPage/Header"
import OfficeComponent from "../../components/ContactPage/OfficeComponent"
import OurAgentsComponent from "../../components/ContactPage/OurAgentsComponent"
import FormComponent from "../../components/ContactPage/FormComponent"
import MapComponent from "../../components/ContactPage/MapComponent"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import OfficeWithoutContentful from "../../components/ContactPage/OfficeComponent/OfficeWithoutContentful"
import MultiMap from "../../components/ContactPage/MapComponent/MultiMap"

const ContactPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query Contact {
      polish: contentfulContact(node_locale: { eq: "pl-PL" }) {
        send
        formSubtitle
        rodo {
          rodo
        }
        message
        name
        mail
        phone
        contact_form
        our_agents
        contact_niepolomice
        location
        office_niepolomice
        debosz_nieruchomosci {
          raw
        }
        opening_hours {
          raw
        }
        rynek_12_3 {
          raw
        }
        validateName
        validatePhone
        validateEmail
        validateMessage
        validateCheckbox
        validateCv
        validateMessageSend
        validateErrors
        validateName2
      }
      english: contentfulContact(node_locale: { eq: "en-US" }) {
        send
        formSubtitle
        rodo {
          rodo
        }
        message
        name
        mail
        phone
        contact_form
        our_agents
        contact_niepolomice
        location
        office_niepolomice
        debosz_nieruchomosci {
          raw
        }
        opening_hours {
          raw
        }
        rynek_12_3 {
          raw
        }
        validateName
        validatePhone
        validateEmail
        validateMessage
        validateCheckbox
        validateCv
        validateMessageSend
        validateErrors
        validateName2
      }
    }
  `)

  useEffect(() => {
    if (lang === "en") {
      navigate("/contact/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  const translationsContact = {
    send: data.send,
    rodo: data.rodo && data.rodo.rodo,
    message: data.message,
    name: data.name,
    mail: data.mail,
    phone: data.phone,
    contact_form: data.contact_form,
    validateName: data.validateName,
    validatePhone: data.validatePhone,
    validateEmail: data.validateEmail,
    validateMessage: data.validateMessage,
    validateCheckbox: data.validateCheckbox,
    validateCv: data.validateCv,
    validateMessageSend: data.validateMessageSend,
    validateErrors: data.validateErrors,
    validateName2: data.validateName2,
    formSubtitle: data.formSubtitle,
  }

  return (
    <Layout>
      <Seo title="Kontakt" />
      <Header
        contact_niepolomice={data.contact_niepolomice}
        goldHeader={false}
      />
      <OfficeComponent
        office_niepolomice={data.office_niepolomice}
        debosz_nieruchomosci={data.debosz_nieruchomosci}
        rynek={data.rynek_12_3}
        opening_hours={data.opening_hours}
      />
      <OfficeWithoutContentful
        office_niepolomice={data.office_niepolomice}
        debosz_nieruchomosci={data.debosz_nieruchomosci}
        rynek={data.rynek_12_3}
        opening_hours={data.opening_hours}
      />
      <MultiMap location={"Nasze Lokalizacje:"} />
      <OurAgentsComponent our_agents={data.our_agents} />
      <FormComponent translations={translationsContact} />
    </Layout>
  )
}

export default ContactPage
